import { defaultLocale } from '@/configs/main';

export const getCurrentLanguage = () => {
	let language = defaultLocale;
	try {
		language = localStorage.getItem('currentLanguage');
		if (!language) {
			let currentLocale = window.navigator.language;
			const reg = /^zh(.*)(CN|cn|Cn)(.*)$/;
			if (currentLocale && currentLocale !== 'en' && reg.test(currentLocale)) {
				currentLocale = 'zh-CN';
			} else {
				currentLocale = 'en';
			}
			if (currentLocale) {
				language = currentLocale;
			}
		}
	} catch (error) {
		console.log(
			'>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
			error
		);
		language = defaultLocale;
	}
	return language;
};

export const setCurrentLanguage = (locale) => {
	try {
		localStorage.setItem('currentLanguage', locale);
	} catch (error) {
		console.log(
			'>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
			error
		);
	}
};

export const setDocumentTitle = (lang) => {
	if (lang === 'zh-CN') {
		document.title = 'PVP - 春华创投';
	} else {
		document.title = 'PVP - Primavera Venture Partners';
	}
};
