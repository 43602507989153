import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { getCurrentLanguage, setDocumentTitle } from './helpers/Utils';
import MainRouter from './Router';
const App = ({ i18n }) => {
	useEffect(() => {
		const lang = getCurrentLanguage();
		i18n.changeLanguage(lang);
		setDocumentTitle(lang);
	}, [i18n]);
	return <MainRouter />;
};

export default withTranslation()(App);
