import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Layout = lazy(() =>
	import(/* webpackChunkName: "views-layout" */ './layouts/main')
);
const Home = lazy(() =>
	import(/* webpackChunkName: "views-home" */ './views/home')
);
const About = lazy(() =>
	import(/* webpackChunkName: "views-about" */ './views/about')
);
const Team = lazy(() =>
	import(/* webpackChunkName: "views-team" */ './views/team')
);
const Portfolio = lazy(() =>
	import(/* webpackChunkName: "views-portfolio" */ './views/portfolio')
);
const Press = lazy(() =>
	import(/* webpackChunkName: "views-press" */ './views/press')
);
const Contact = lazy(() =>
	import(/* webpackChunkName: "views-contact" */ './views/contact')
);
const NotFound = lazy(() =>
	import(/* webpackChunkName: "views-404" */ './views/404')
);

const MainRouter = () => {
	return (
		<Suspense fallback={<div className="loading" />}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="about" element={<About />} />
						<Route path="team" element={<Team />} />
						<Route path="portfolio" element={<Portfolio />} />
						<Route path="press" element={<Press />} />
						<Route path="contact" element={<Contact />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
};

export default MainRouter;
