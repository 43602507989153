// https://www.i18next.com/overview/configuration-options  使用的国际化类库
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { defaultLocale, version } from '@/configs/main';

const defaultLanguage = defaultLocale;
const lng = defaultLanguage;

i18n
	.use(XHR)
	.use(initReactI18next)
	.init({
		lng,
		fallbackLng: defaultLocale,
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			queryStringParams:
				process.env.NODE_ENV === 'development' ? {} : { v: version },
		},
		ns: ['general', 'home', 'about', 'team', 'portfolio', 'press', 'contact'],
		defaultNS: 'general',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
			useSuspense: true,
		},
	});

export default i18n;
