// Language
export const defaultLocale = 'en';
export const defaultDirection = 'ltr';
export const localeOptions = [
	{ id: 'en', name: 'English', direction: 'ltr' },
	{ id: 'zh-CN', name: 'Chinese', direction: 'ltr' },
];

// Storage keys
export const localeStorageKey = '__locale';
export const localeDirectionStorageKey = '__locale_direction';

export const videoStorage =
	'https://primavera-pvp.oss-cn-hongkong.aliyuncs.com/';
	
export * from './defaultValues';
